import React from 'react';
import Layout from '../components/layout';

const styling = {
  backgroundImage: "url('/images/gray1px.jpg')",
  backgroundRepeat: 'repeat-y',
  backgroundPosition: 'center center'
};
const Index = () => (
  <Layout>
    <div className="page-single first-year-page">
      <h2>
        How your child plays, learns, speaks, acts, and moves offers important
        clues about your child's development. Developmental milestones are
        things most children can do by a certain age.
      </h2>

      <div className="stages" style={styling}>
        <strong>What most babies do by this age</strong>

        <div className="stages-inner">
          <div className="stage">
            <h3>2 months</h3>
            <h6>Social and Emotional</h6>
            <ul>
              <li>Begins to smile at people</li>
              <li>
                Can briefly calm herself (may bring hands to mouth and suck on
                hand)
              </li>
              <li>Tries to look at parent</li>
            </ul>
            <h6>Language/Communication</h6>
            <ul>
              <li>Coos, makes gurgling sounds</li>
              <li>Turns head toward sounds</li>
            </ul>
            <h6>Cognitive (learning, thinking, problem-solving)</h6>
            <ul>
              <li>Pays attention to faces </li>
              <li>
                Begins to follow things with eyes and recognize people at a
                distance 
              </li>
              <li>
                Begins to act bored (cries, fussy) if activity doesn't change
              </li>
            </ul>
            <h6>Movement/Physical Development</h6>
            <ul>
              <li>
                Can hold head up and begins to push up when lying on tummy 
              </li>
              <li>Makes smoother movements with arms and legs </li>
            </ul>

            <div className="warning">
              <h4>Pay attention and consult a specialist if your child:</h4>
              <ul>
                <li>Doesn't respond to loud sounds</li>
                <li>Doesn't watch things as they move</li>
                <li>Doesn't smile at people</li>
                <li>Doesn't bring hands to mouth</li>
                <li>Can't hold head up when pushing up when on tummy</li>
              </ul>
            </div>
            <div className="circle-pic medium">
              <em></em>
              <em></em>
              <div className="circle-pic-inner">
                <img src="/images/baby-sleep-2.jpg" />
              </div>
              <em></em>
            </div>
          </div>
        </div>

        <div className="stages-inner">
          <div className="stage">
            <h3>4 months</h3>
            <h6>Social and Emotional</h6>
            <ul>
              <li>Smiles spontaneously, especially at people </li>
              <li>
                Likes to play with people and might cry when playing stops 
              </li>
              <li>
                Copies some movements and facial expressions, like smiling or
                frowning
              </li>
            </ul>
            <h6>Language/Communication</h6>
            <ul>
              <li>Begins to babble </li>
              <li>Babbles with expression and copies sounds he hears</li>
              <li>
                Cries in different ways to show hunger, pain, or being tired
              </li>
            </ul>

            <h6>Cognitive (learning, thinking, problem-solving)</h6>
            <ul>
              <li>Lets you know if he is happy or sad </li>
              <li>Responds to affection</li>
              <li>Reaches for toy with one hand </li>
              <li>
                Uses hands and eyes together, such as seeing a toy and reaching
                for it 
              </li>
              <li>Follows moving things with eyes from side to side </li>
              <li>Watches faces closely </li>
              <li>Recognizes familiar people and things at a distance</li>
            </ul>
            <h6>Movement/Physical Development</h6>
            <ul>
              <li>Holds head steady, unsupported </li>
              <li>Pushes down on legs when feet are on a hard surface </li>
              <li>May be able to roll over from tummy to back </li>
              <li>Can hold a toy and shake it and swing at dangling toys </li>
              <li>Brings hands to mouth </li>
              <li>When lying on stomach, pushes up to elbows </li> 
            </ul>
            <div className="warning">
              <h4>Pay attention and consult a specialist if your child</h4>
              <ul>
                <li>Doesn't watch things as they move</li>
                <li>Doesn't smile at people</li>
                <li>Can't hold head steady</li>
                <li>Doesn't coo or make sounds</li>
                <li>Doesn't bring things to mouth</li>
                <li>
                  Doesn't push down with legs when feet are placed on a hard
                  surface
                </li>
                <li>Has trouble moving one or both eyes in all directions</li>
              </ul>
            </div>
            <div className="circle-pic medium">
              <em></em>
              <em></em>
              <div className="circle-pic-inner">
                <img src="/images/baby-smile.jpg" />
              </div>
              <em></em>
            </div>
          </div>
        </div>

        <div className="stages-inner">
          <div className="stage">
            <h3>6 month</h3>
            <h6>Social and Emotional</h6>
            <ul>
              <li>
                Knows familiar faces and begins to know if someone is a
                stranger 
              </li>
              <li>Likes to play with others, especially parents </li>
              <li>Responds to other people's emotions and often seems happy</li>
              <li>Likes to look at self in a mirror</li>
            </ul>
            <h6>Language/Communication</h6>
            <ul>
              <li>Responds to sounds by making sounds </li>
              <li>
                Strings vowels together when babbling ("ah," "eh," "oh") and
                likes taking turns with parent while making sounds 
              </li>
              <li>Responds to own name </li>
              <li>Makes sounds to show joy and displeasure </li>
              <li>Begins to say consonant sounds (jabbering with "m," "b")</li>
            </ul>
            <h6>Cognitive (learning, thinking, problem-solving)</h6>
            <ul>
              <li>Looks around at things nearby </li>
              <li>Brings things to mouth </li>
              <li>
                Shows curiosity about things and tries to get things that are
                out of reach 
              </li>
              <li>Begins to pass things from one hand to the other</li>
            </ul>
            <h6>Movement/Physical Development</h6>
            <ul>
              <li>
                Rolls over in both directions (front to back, back to front) 
              </li>
              <li>Begins to sit without support </li>
              <li>When standing, supports weight on legs and might bounce </li>
              <li>
                Rocks back and forth, sometimes crawling backward before moving
                forward 
              </li>
            </ul>
            <div className="warning">
              <h4>Pay attention and consult a specialist if your child:</h4>
              <ul>
                <li>Doesn't try to get things that are in reach</li>
                <li>Shows no affection for caregivers</li>
                <li>Doesn't respond to sounds around him</li>
                <li>Has difficulty getting things to mouth</li>
                <li>Doesn't make vowel sounds ("ah", "eh", "oh")</li>
                <li>Doesn't roll over in either direction</li>
                <li>Doesn't laugh or make squealing sounds</li>
                <li>Seems very stiff, with tight muscles</li>
                <li>Seems very floppy, like a rag doll</li>
              </ul>
            </div>
            <div className="circle-pic medium">
              <em></em>
              <em></em>
              <div className="circle-pic-inner">
                <img src="/images/baby-yawn.jpg" />
              </div>
              <em></em>
            </div>
          </div>
        </div>

        <div className="stages-inner">
          <div className="stage">
            <h3>9 month</h3>
            <h6>Social and Emotional</h6>
            <ul>
              <li>May be afraid of strangers </li>
              <li>May be clingy with familiar adults </li>
              <li>Has favorite toys</li>
            </ul>
            <h6>Language/Communication</h6>
            <ul>
              <li>Understands "no" </li>
              <li>
                Makes a lot of different sounds like "mamamama" and
                "bababababa" 
              </li>
              <li>Copies sounds and gestures of others </li>
              <li>Uses fingers to point at things</li>
            </ul>
            <h6>Cognitive (learning, thinking, problem-solving)</h6>
            <ul>
              <li>Watches the path of something as it falls </li>
              <li>Looks for things she sees you hide </li>
              <li>Plays peek-a-boo </li>
              <li>Puts things in his mouth </li>
              <li>Moves things smoothly from one hand to the other </li>
              <li>
                Picks up things like cereal o's between thumb and index finger 
              </li>
            </ul>
            <h6>Movement/Physical Development</h6>
            <ul>
              <li>Stands, holding on </li>
              <li>Can get into sitting position </li>
              <li>Sits without support </li>
              <li>Pulls to stand </li>
              <li>Crawls</li>
            </ul>
             
            <div className="warning">
              <h4>Pay attention and consult a specialist if your child</h4>
              <ul>
                <li>Doesn't bear weight on legs with support</li>
                <li>Doesn't sit with help</li>
                <li>Doesn't babble ("mama", "baba", "dada")</li>
                <li>Doesn't play any games involving back-and-forth play</li>
                <li>Doesn't respond to own name</li>
                <li>Doesn't seem to recognize familiar people</li>
                <li>Doesn't look where you point</li>
                <li>Doesn't transfer toys from one hand to the other</li>
              </ul>
            </div>
            <div className="circle-pic medium">
              <em></em>
              <em></em>
              <div className="circle-pic-inner">
                <img src="/images/baby-sit.jpg" />
              </div>
              <em></em>
            </div>
          </div>
        </div>
        <div className="stages-inner">
          <div className="stage">
            <h3>1 year</h3>
            <h6>Social and Emotional</h6>
            <ul>
              <li>Is shy or nervous with strangers </li>
              <li>Cries when mom or dad leaves </li>
              <li>Has favorite things and people </li>
              <li>Shows fear in some situations </li>
              <li>Hands you a book when he wants to hear a story </li>
              <li>Repeats sounds or actions to get attention </li>
              <li>Puts out arm or leg to help with dressing </li>
              <li>Plays games such as "peek-a-boo" and "pat-a-cake"</li>
            </ul>
            <h6>Language/Communication</h6>
            <ul>
              <li>Responds to simple spoken requests </li>
              <li>
                Uses simple gestures, like shaking head "no" or waving
                "bye-bye" 
              </li>
              <li>
                Makes sounds with changes in tone (sounds more like speech) 
              </li>
              <li>Says "mama" and "dada" and exclamations like "uh-oh!" </li>
              <li>Tries to say words you say</li>
            </ul>
            <h6>Cognitive (learning, thinking, problem-solving)</h6>
            <ul>
              <li>
                Explores things in different ways, like shaking, banging,
                throwing 
              </li>
              <li>Finds hidden things easily </li>
              <li>Looks at the right picture or thing when it's named </li>
              <li>Copies gestures </li>
              <li>
                Starts to use things correctly; for example, drinks from a cup,
                brushes hair 
              </li>
              <li>Bangs two things together </li>
              <li>
                Puts things in a container, takes things out of a container 
              </li>
              <li>Lets things go without help </li>
              <li>Pokes with index (pointer) finger </li>
              <li>Follows simple directions like "pick up the toy"</li>
            </ul>
            <h6>Movement/Physical Development</h6>
            <ul>
              <li>Gets to a sitting position without help </li>
              <li>
                Pulls up to stand, walks holding on to furniture ("cruising") 
              </li>
              <li>May take a few steps without holding on </li>
              <li>May stand alone </li>
            </ul>
            <div className="warning">
              <h4>Pay attention and consult a specialist if your child</h4>
              <ul>
                <li>Doesn't crawl</li>
                <li>Can't stand when supported</li>
                <li>Doesn't search for things that she sees you hide</li>
                <li>Doesn't say single words like "mama" or "dada"</li>
                <li>Doesn't learn gestures like waving or shaking head</li>
                <li>Doesn't point to things</li>
                <li>Loses skills he once had</li>
              </ul>
            </div>
            <div className="circle-pic medium">
              <em></em>
              <em></em>
              <div className="circle-pic-inner">
                <img src="/images/baby-stand.jpg" />
              </div>
              <em></em>
            </div>
          </div>
          <p className="info-source">www.cdc.gov</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
